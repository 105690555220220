import React, {useId} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {TYPE_OPTIONS} from "./type";

const AttributeTypeSelect = React.forwardRef(({id, value, defaultValue, onChange, onBlur, disabled = false}, ref) => {
    const labelId = useId();
    return (
        <FormControl>
            <InputLabel id={labelId || id}>Type</InputLabel>
            <Select labelId={labelId || id}
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={disabled}
            >
                {TYPE_OPTIONS.map(({key, label}, i) => (<MenuItem key={i} value={key}>{label}</MenuItem>))}
            </Select>
        </FormControl>
    );
});

export default AttributeTypeSelect;
import {useRef, useState, useCallback} from "react";
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popper
} from "@mui/material";
import {ArrowDropDown} from "@mui/icons-material";
import {useLocation} from "wouter";

export default function ViewAndActionsButton({onView, actions}) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [, setLocation] = useLocation();

    const handleViewClick = useCallback(() => {
        if (typeof onView === 'string') {
            setLocation(onView);
        } else if (typeof onView === 'function') {
            onView(); 
        }
    }, [onView, setLocation]);
    
    const handleActionClick = useCallback((onClick) => {
        if (typeof onClick === 'string') {
            setLocation(onClick);
        } else if (typeof onClick === 'function') {
            onClick();
        }
    }, [setLocation]);

    const handleToggle = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
    }, [setOpen]);

    const handleClose = useCallback((event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    }, [setOpen]);

    return (
        <>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <Button onClick={handleViewClick}>View</Button>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {Object.entries(actions).map(([key, action]) => (
                                        <MenuItem
                                            key={key}
                                            onClick={() => handleActionClick(action.onClick)}
                                        >
                                            {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
                                            {action.text}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
import {Controller, useFieldArray} from "react-hook-form";
import {Button, FormControl, IconButton, OutlinedInput, Stack} from "@mui/material";
import {Add, CancelOutlined} from "@mui/icons-material";

export default function AttributeOptionsEditor({name, control, disabled = false}) {
    const {fields, remove, append} = useFieldArray({
        control,
        name,
        keyName: 'key'
    });

    const addNewOption = () => {
        append({label: ''});
        // hack!
        const newField = `${name}.${fields.length}.label`;
        setTimeout(() => {
            const input = document.querySelector(`[name="${newField}"]`);
            if (input) {
                input.focus();
            }
        }, 0);
    };

    return (
        <Stack direction="column" alignItems="stretch" justifyContent="start" spacing={2}>
            {fields.map((field, index) => (
                <Controller
                    key={field.key}
                    name={`${name}.${index}.label`}
                    control={control}
                    render={({field, fieldState: {error}}) => (
                        <FormControl variant="outlined">
                            <OutlinedInput
                                endAdornment={
                                    <IconButton onClick={() => remove(index)}
                                                onMouseDown={e => e.preventDefault()}
                                                disabled={disabled}
                                    >
                                        <CancelOutlined/>
                                    </IconButton>
                                }
                                error={field.error?.message}
                                disabled={disabled}
                                {...field}
                            />
                        </FormControl>
                    )}
                />
            ))}
            <Button variant="text" onClick={addNewOption} disabled={disabled}>
                <Add/>
            </Button>
        </Stack>
    );
}
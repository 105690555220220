import MainContainer from "./containers/MainContainer";
import Header from "./components/Header";

function App() {
    return (
        <>
            <Header/>
            <MainContainer/>
        </>
    )
}

export default App;

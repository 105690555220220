import {gql} from "@apollo/client";

const FIND_COMPONENT_TYPES = gql`
    query FindComponentTypes($before: String, $after: String, $cursor: CursorRequest, $filter: ComponentTypeFilter) {
        findComponentType(before: $before, after: $after, cursor: $cursor, filter: $filter) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
            edges {
                cursor
                node {
                    id
                    name
                    parent {
                        id
                        name
                    }
                    attributes {
                        id
                        name
                        type
                    }
                }
            }
        }
    }
`;

export default FIND_COMPONENT_TYPES;
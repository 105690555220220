import {Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {Link as LinkRouter} from "wouter";
import FIND_ATTRIBUTES from "../../../operations/queries/findAttributes";
import InfiniteQuery from "../../../components/InfiniteQuery";
import PageHeader from "../PageHeader";
import PageTitle from "../../../components/PageTitle";
import AttributeRow from "./AttributeRow";
import AppBreadcrumbs from "../../../components/AppBreadcrumbs";

export default function AttributeList() {
    return (
        <Box pb={10}>
            <PageTitle title="Attributes"/>
            <AppBreadcrumbs title="Attributes"/>
            <PageHeader title="Attributes">
                <Button variant="contained" href="/attributes/new" component={LinkRouter}>New</Button>
            </PageHeader>
            <InfiniteQuery query={FIND_ATTRIBUTES} resultKey="findAttributes" cursor={{limit: 50, on: 'name'}}>
                {
                    nodes => (
                        <TableContainer component={Paper}>
                            <Table sx={{width: '100%'}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Prefix</TableCell>
                                        <TableCell>Postfix</TableCell>
                                        <TableCell>SI Notation</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {nodes.map(row => <AttributeRow row={row} key={row.id}/>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                }
            </InfiniteQuery>
        </Box>
    )
}
import {Controller} from "react-hook-form";
import {FormControlLabel, FormGroup, Switch} from "@mui/material";

export default function ControlledSwitch({name, label, control, disabled = false}) {
    return (
        <Controller name={name}
                    control={control}
                    render={({field}) => (
                        <FormGroup>
                            <FormControlLabel label={label} disabled={disabled} control={<Switch checked={field.value} {...field}/>}/>
                        </FormGroup>
                    )}
        />
    )
}
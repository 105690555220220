import {gql} from "@apollo/client";

const CREATE_ATTRIBUTE = gql`
    mutation CreateAttribute($attribute: AttributeCreateInput!) {
        createAttribute(create: $attribute) {
            id
            name
            type
            prefix
            postfix
            siNotation
            options {
                id
                label
            }
        }
    }
`;

export default CREATE_ATTRIBUTE;
import {Link, Typography, Breadcrumbs} from "@mui/material";
import {Link as RouterLink} from 'wouter';

export default function AppBreadcrumbs({attributes, attribute, componentTypes, title}) {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link href="/" underline="hover" component={RouterLink}>Home</Link>
            {attributes && (<Link href="/attributes" underline="hover" component={RouterLink}>Attributes</Link>)}
            {attribute && (<Link href={`/attributes/${attribute.id}`} underline="hover" component={RouterLink}>{attribute.name}</Link>)}
            {componentTypes && (<Link href="/component-types" underline="hover" component={RouterLink}>Component Types</Link>)}
            {title && <Typography color="text.primary">{title}</Typography>}
        </Breadcrumbs>
    )
}
import {TableCell, TableRow} from "@mui/material";
import ViewAndActionsButton from "../../../components/ViewAndActionsButton";
import {Cancel, CheckCircle, EditTwoTone} from "@mui/icons-material";
import AttributeTypeDisplay from "../../../components/attributes/AttributeTypeDisplay";

export default function AttributeRow({row}) {
    return (
        <TableRow>
            <TableCell>{row.name}</TableCell>
            <TableCell><AttributeTypeDisplay type={row.type}/></TableCell>
            <TableCell>{row.prefix}</TableCell>
            <TableCell>{row.postfix}</TableCell>
            <TableCell>{row.siNotation ? <CheckCircle color="success"/> : <Cancel/>}</TableCell>
            <TableCell>
                <ViewAndActionsButton id={row.id} onView={`/attributes/${row.id}`} actions={{
                    edit: {
                        text: 'Edit',
                        icon: <EditTwoTone/>,
                        onClick: `/attributes/${row.id}/edit`
                    }
                }}/>
            </TableCell>
        </TableRow>
    );
}
import {Grid, Typography} from "@mui/material";

export default
function GridDataDisplay({title, component = "p", children}) {
    return (
        <>
            <Grid item xs={12} md={3} xl={2}><Typography variant="h6" component="h3">{title}</Typography></Grid>
            <Grid item xs={12} md={9} xl={10}><Typography variant="subtitle1" component={component}>{children}</Typography></Grid>
        </>
    )
}
import { teal, amber } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: teal["500"],
        },
        secondary: {
            main: amber["400"],
        },
    },
});

export default theme;
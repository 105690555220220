import {Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {Link as LinkRouter} from "wouter";
import PageTitle from "../../../components/PageTitle";
import AppBreadcrumbs from "../../../components/AppBreadcrumbs";
import PageHeader from "../PageHeader";
import FIND_COMPONENT_TYPES from "../../../operations/queries/findComponentTypes";
import InfiniteQuery from "../../../components/InfiniteQuery";
import ComponentTypeRow from "./ComponentTypeRow";

export default function ComponentTypeList() {
    return (
        <Box pb={10}>
            <PageTitle title="Component Types"/>
            <AppBreadcrumbs title="Component Types"/>
            <PageHeader title="Component Types">
                <Button variant="contained" href="/component-types/new" component={LinkRouter}>New</Button>
            </PageHeader>
            <InfiniteQuery query={FIND_COMPONENT_TYPES} resultKey="findComponentType" cursor={{limit: 50, on: 'name'}}>
                {
                    nodes => (
                        <TableContainer component={Paper}>
                            <Table sx={{width: '100%'}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Parent</TableCell>
                                        <TableCell>Attributes</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {nodes.map(row => (<ComponentTypeRow key={row.id} row={row}/>))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                }
            </InfiniteQuery>
        </Box>
    )
}
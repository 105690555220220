import {useQuery} from "@apollo/client";
import {useEffect} from "react";
import {Alert, Box, CircularProgress} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";

export default function InfiniteQuery({query, resultKey, cursor = {}, filter = {}, children}) {
    const {data, error, loading, refetch, fetchMore} = useQuery(query, {
        variables: {
            after: null,
            cursor,
            filter
        }
    });

    useEffect(() => {
        refetch({after: null})
            .catch(err => console.error(err));
    }, [refetch]);

    let pageInfo;
    let nodes = [];
    if (data && data[resultKey]) {
        pageInfo = data[resultKey].pageInfo;
        nodes = data[resultKey].edges.map(x => x.node);
    }

    const loadNext = () => {
        if (pageInfo?.hasNextPage) {
            fetchMore({
                variables: {after: pageInfo.endCursor, limit: 2}
            })
                .catch(err => console.error(err));
        }
    };

    return (
        <>
            {error && <Alert severity="error">Error getting results: {error.message}</Alert>}
            <InfiniteScroll next={loadNext}
                            hasMore={pageInfo?.hasNextPage || false}
                            dataLength={nodes.length}
                            style={{overflow: 'visible'}}
                            loader={null}>
                {typeof children === 'function' ? children(nodes) : ''}
                {loading && (
                    <Box sx={{display: 'flex', justifyContent: 'center'}} py={2}>
                        <CircularProgress/>
                    </Box>
                )}
            </InfiniteScroll>
        </>
    )
}
import {useCallback, useState} from "react";
import {gql, useMutation} from "@apollo/client";
import {Alert, Box, Button, CircularProgress, Container, TextField, Typography} from "@mui/material";
import {setCurrentLogin} from "../utils/currentLogin";

const loginRequest = gql`
    mutation LoginRequest($username: String!, $password: String!) {
        loginUserAccount(login: $username, password: $password) {
            success
            token
            expires
        }
    }
`;

export default function LoginForm() {
    const [doLogin, {loading, error}] = useMutation(loginRequest);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginFailed, setLoginFailed] = useState(false);

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        setLoginFailed(false);
        doLogin({variables: {username, password}})
            .then(({data: {loginUserAccount: {success, token, expires}}}) => {
                if (success) {
                    setCurrentLogin({
                        login: username,
                        token,
                        expires: new Date(expires)
                    });
                    setUsername('');
                    setPassword('');
                } else {
                    setLoginFailed(true);
                }
            });
    }, [doLogin, username, password]);

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" component="h2" sx={{marginBottom: 3}}>Login</Typography>
            <form onSubmit={onSubmit}>
                {error && (
                    <Alert severity="error" sx={{marginBottom: 2}}>{error.message}</Alert>
                )}
                {loginFailed && (
                    <Alert severity="warning" sx={{marginBottom: 2}}>
                        Could not log you in. Wrong username or password?
                    </Alert>
                )}
                <Box sx={{display: 'grid', gap: 1}}>
                    <TextField id="login-username"
                               label="Username"
                               variant="outlined"
                               disabled={loading}
                               error={loginFailed}
                               value={username}
                               onChange={({target}) => setUsername(target.value)}
                    />
                    <TextField id="login-password"
                               label="Password"
                               type="password"
                               variant="outlined"
                               disabled={loading}
                               error={loginFailed}
                               value={password}
                               onChange={({target}) => setPassword(target.value)}
                    />
                    <Button type="submit" variant="contained" disabled={loading}>
                        {loading ? <CircularProgress color="inherit" size={24}/> : 'Login'}
                    </Button>
                </Box>
            </form>
        </Container>
    )
}
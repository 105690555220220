import React from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import {RecoilRoot} from "recoil";
import RecoilSyncStore from "./recoil/RecoilSyncStore";
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from "./theme";
import client from "./graphql/client";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ApolloProvider client={client}>
          <RecoilRoot>
              <RecoilSyncStore>
                  <ThemeProvider theme={theme}>
                      <CssBaseline/>
                      <App />
                  </ThemeProvider>
              </RecoilSyncStore>
          </RecoilRoot>
      </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {gql} from "@apollo/client";

const UPDATE_ATTRIBUTE = gql`
    mutation UpdateAttribute($id: ID!, $update: AttributeUpdateInput!) {
        updateAttribute(id: $id, update: $update) {
            id
            name
            type
            prefix
            postfix
            siNotation
            options {
                id
                label
            }
        }
    }
`;

export default UPDATE_ATTRIBUTE;
/* eslint-disable react-hooks/rules-of-hooks */
import {useQuery} from "@apollo/client";
import NotFoundBoundary from "./NotFoundBoundary";
import LoadingBoundary from "./LoadingBoundary";

const ItemQueryWrapper = (InnerComponent) => {
    return ({id, query, dataKey, useNotFound = true, ...props}) => {
        const {data, loading, error} = useQuery(query, {
            variables: {id}
        });

        const dataItem = data?.[dataKey];

        const innerProps = {
            [dataKey]: dataItem,
            ...props,
            id
        };

        const loadingWrapper = (
            <LoadingBoundary loading={loading} error={error}>
                {() => <InnerComponent {...innerProps} />}
            </LoadingBoundary>
        );

        if (useNotFound) {
            return (
                <NotFoundBoundary data={dataItem} enable={!loading}>{loadingWrapper}</NotFoundBoundary>
            );
        }
        return loadingWrapper;
    }
};

export default ItemQueryWrapper;

import {useMutation} from "@apollo/client";
import GET_ATTRIBUTE from "../../../operations/queries/attribute";
import PageTitle from "../../../components/PageTitle";
import AppBreadcrumbs from "../../../components/AppBreadcrumbs";
import PageHeader from "../PageHeader";
import UPDATE_ATTRIBUTE from "../../../operations/mutations/updateAttribute";
import {useLocation} from "wouter";
import AttributeForm from "../../../components/attributes/AttributeForm";
import ItemQueryWrapper from "../../../components/ItemQueryWrapper";
import {Alert} from "@mui/material";

const AttributeEditInner = ItemQueryWrapper(({id, attribute}) => {
    const [, setLocation] = useLocation();

    const [updateAttribute, {loading: doingUpdate, error: updateError}] = useMutation(UPDATE_ATTRIBUTE, {
        variables: {id}
    });

    const onSave = data => {
        updateAttribute({variables: {update: data}})
            .then(() => setLocation(`/attributes/${id}`))
            .catch(err => console.error(err));
    };

    return (
        <>
            <PageTitle title={"Attribute"}/>
            <AppBreadcrumbs attributes attribute={attribute} title="Edit"/>
            <PageHeader title={"Edit Attribute"}/>
            {updateError && <Alert severity="error">{updateError.message ?? updateError}</Alert>}
            <AttributeForm attribute={attribute} onSave={onSave} disabled={doingUpdate}/>
        </>
    );
});

export default function AttributeEdit({params: {id}}) {
    return <AttributeEditInner id={id} query={GET_ATTRIBUTE} dataKey="attribute"/>
}
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useId} from "react";

export default function ConfirmDelete({open, thing, onClosed}) {
    const handleConfirm = () => onClosed(true);
    const handleCancel = () => onClosed(false);

    const titleId = useId();
    const contentId = useId();

    return (
        <Dialog open={open}
                onClose={handleCancel}
                aria-labelledby={titleId}
                aria-describedby={contentId}
        >
            <DialogTitle id={titleId}>
                Confirm Deletion
            </DialogTitle>
            <DialogContent>
                <DialogContentText id={contentId}>
                    Do you really want delete this {thing}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} autoFocus>Cancel</Button>
                <Button onClick={handleConfirm} color="error">Delete</Button>
            </DialogActions>
        </Dialog>
    )
}
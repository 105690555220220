import storage from "../recoil/storage";
import onStorageChanged from "./storageChanged";
import {useEffect, useState} from "react";

/**
 * @typedef {Object} CurrentLogin
 * @property {string} login
 * @property {string} token
 * @property {Date} expires
 */

/**
 * @type {(function(login: CurrentLogin) : void)[]}
 */
const changeSubscribers = [];

onStorageChanged('login', () => {
    changeSubscribers.forEach(x => x(getCurrentLogin()));
});

/**
 * @param {CurrentLogin|null} login
 */
export function setCurrentLogin(login) {
    storage.set('login', login);
    changeSubscribers.forEach(x => x(login));
}

/**
 * @return {CurrentLogin}
 */
export function getCurrentLogin() {
    const login = storage.get('login');
    if (login?.expires) {
        login.expires = new Date(login.expires);
    }
    return login;
}

/**
 *
 * @param {function(login: CurrentLogin) : void} callback
 * @return {function() : void}
 */
export function onCurrentLoginChange(callback) {
    changeSubscribers.push(callback);
    return () => {
        const index = changeSubscribers.findIndex(x => x === callback);
        if (index !== -1) {
            changeSubscribers.splice(index, 1);
        }
    };
}

export function useCurrentLogin() {
    const [login, setLogin] = useState(getCurrentLogin());

    useEffect(() => {
        return onCurrentLoginChange(newLogin => setLogin(newLogin));
    });

    return login;
}
import {Chip, TableCell, TableRow} from "@mui/material";
import {EditTwoTone} from "@mui/icons-material";
import ViewAndActionsButton from "../../../components/ViewAndActionsButton";

export default function ComponentTypeRow({row}) {
    return (
        <TableRow>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.parent?.name}</TableCell>
            <TableCell>{row.attributes.map(x => <Chip label={x.name} variant="outlined"/> )}</TableCell>
            <TableCell>
                <ViewAndActionsButton id={row.id} onView={`/component-types/${row.id}`} actions={{
                    edit: {
                        text: 'Edit',
                        icon: <EditTwoTone/>,
                        onClick: `/component-types/${row.id}/edit`
                    }
                }}/>
            </TableCell>
        </TableRow>
    );
}
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useId} from "react";

export default function ConfirmLogoutDialog({open, onClosed}) {
    const handleLogoutConfirm = () => onClosed(true);
    const handleCancel = () => onClosed(false);

    const titleId = useId();
    const contentId = useId();

    return (
        <Dialog open={open}
                onClose={handleCancel}
                aria-labelledby={titleId}
                aria-describedby={contentId}
        >
            <DialogTitle id={titleId}>
                Confirm Logout
            </DialogTitle>
            <DialogContent>
                <DialogContentText id={contentId}>
                    Do you really want to logout? You will lose any unsaved changes.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleLogoutConfirm} autoFocus>Logout</Button>
            </DialogActions>
        </Dialog>
    )
}
import {gql} from '@apollo/client';

const GET_COMPONENT_TYPE = gql`
    query GetComponentType($id: ID!) {
        componentType(id: $id) {
            id
            name
            parent {
                id
                name
            }
            attributes {
                id
                type
                name
            }
            subTypes {
                id
                name
            }
        }
    }
`;

export default GET_COMPONENT_TYPE;
import {gql} from "@apollo/client";

const FIND_ATTRIBUTES = gql`
    query FindAttributes($before: String, $after: String, $cursor: CursorRequest, $filter: AttributeFilter) {
        findAttributes(before: $before, after: $after, cursor: $cursor, filter: $filter) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
            edges {
                cursor
                node {
                    id
                    name
                    type
                    postfix
                    prefix
                    siNotation
                }
            }
        }
    }
`;

export default FIND_ATTRIBUTES;
import CREATE_ATTRIBUTE from "../../../operations/mutations/createAttribute";
import {useLocation} from "wouter";
import {useMutation} from "@apollo/client";
import PageTitle from "../../../components/PageTitle";
import AppBreadcrumbs from "../../../components/AppBreadcrumbs";
import PageHeader from "../PageHeader";
import AttributeForm from "../../../components/attributes/AttributeForm";
import {Alert} from "@mui/material";

export default function AttributeNew() {
    const [, setLocation] = useLocation();

    const [createAttribute, {error, loading}] = useMutation(CREATE_ATTRIBUTE, {});

    const onSave = attribute => {
        createAttribute({variables: {attribute}})
            .then(({data: {createAttribute: {id}}}) => {
                setLocation(`/attributes/${id}`);
            })
            .catch(err => console.error(err));
    }

    return (
        <>
            <PageTitle title={"Attribute"}/>
            <AppBreadcrumbs attributes title="New"/>
            <PageHeader title={"New Attribute"}/>
            {error && <Alert severity="error">{error.message ?? error}</Alert>}
            <AttributeForm onSave={onSave} disabled={loading}/>
        </>
    );
}
import {useState} from "react";
import GET_COMPONENT_TYPE from "../../../operations/queries/componentType";
import PageTitle from "../../../components/PageTitle";
import AppBreadcrumbs from "../../../components/AppBreadcrumbs";
import PageHeader from "../PageHeader";
import {Button, ButtonGroup, Chip, Grid, Link} from "@mui/material";
import {Link as LinkRouter} from "wouter";
import DeleteItemDialog from "../../../components/DeleteItemDialog";
import DELETE_COMPONENT_TYPE from "../../../operations/mutations/deleteComponentType";
import GridDataDisplay from "../../../components/GridDataDisplay";
import ItemQueryWrapper from "../../../components/ItemQueryWrapper";

const ComponentTypeViewInner = ItemQueryWrapper(({id, componentType}) => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const onDeleteClosed = () => setDeleteDialogOpen(false);
    const onDeleteClicked = () => setDeleteDialogOpen(true);

    return (
        <>
            <PageTitle title="Component Type"/>
            <AppBreadcrumbs componentTypes title={componentType?.name}/>
            <PageHeader title="Component Type">
                <ButtonGroup variant="contained">
                    <Button href={`/component-types/${id}/edit`} component={LinkRouter}>Edit</Button>
                    <Button color="error" onClick={onDeleteClicked}>Delete</Button>
                </ButtonGroup>
            </PageHeader>
            <DeleteItemDialog id={id}
                              thing="component-type"
                              afterUrl="/component-types"
                              mutation={DELETE_COMPONENT_TYPE}
                              checkResult="deleteComponentType"
                              open={deleteDialogOpen}
                              onClosed={onDeleteClosed}
            />
            <Grid container spacing={2}>
                <GridDataDisplay title="Name">{componentType.name}</GridDataDisplay>
                {componentType.parent && (
                    <GridDataDisplay title="Parent Type">
                        <Link href={`/component-types/${componentType.parent.id}`} component={LinkRouter}>
                            {componentType.parent.name}
                        </Link>
                    </GridDataDisplay>
                )}
                {componentType.attributes.length === 0 && (
                    <GridDataDisplay title="Attributes">
                        <em>None</em>
                    </GridDataDisplay>
                )}
                {componentType.attributes.length > 0 && (
                    <GridDataDisplay title="Attributes" component="div">
                        {componentType.attributes.map(attribute => (
                            <Chip key={attribute.id}
                                  label={(
                                      <Link underline="hover"
                                            href={`/attributes/${attribute.id}`}
                                            component={LinkRouter}>
                                          {attribute.name}
                                      </Link>
                                  )}
                            />
                        ))}
                    </GridDataDisplay>
                )}
            </Grid>
        </>
    );
});

export default function ComponentTypeView({params: {id}}) {
    return <ComponentTypeViewInner id={id} query={GET_COMPONENT_TYPE} dataKey="componentType"/>;
}
export const TYPE_OPTIONS = [
    {
        key: 'FLOAT',
        label: 'Number',
        hasOptions: false
    },
    {
        key: 'INTEGER',
        label: 'Whole Number',
        hasOptions: false
    },
    {
        key: 'MULTI_OPTION',
        label: 'Multiple Options',
        hasOptions: true
    },
    {
        key: 'SINGLE_OPTION',
        label: 'Single Option',
        hasOptions: true
    },
    {
        key: 'TEXT',
        label: 'Text',
        hasOptions: false
    },
    {
        key: 'TOGGLE',
        label: 'Toggle/Switch',
        hasOptions: false
    },
];

export function hasOptions(type) {
    return TYPE_OPTIONS.find(x => x.key === type)?.hasOptions ?? false;
}
import {Controller} from "react-hook-form";
import {TextField} from "@mui/material";

export default
function ControlledTextField({name, label, control, disabled = false}) {
    return (
        <Controller name={name}
                    control={control}
                    render={({field, fieldState: {error}}) => (
                        <TextField label={label} disabled={disabled} error={error?.message} {...field}/>
                    )}
        />
    );
}
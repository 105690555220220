import {Typography} from "@mui/material";
import PageHeader from "./PageHeader";
import PageTitle from "../../components/PageTitle";

export default function NotFound() {
    return (
        <>
            <PageTitle title="Not Found"/>
            <PageHeader title="Not Found"/>
            <Typography paragraph>
                Sorry, that page or resource doesn't exist.
            </Typography>
        </>
    );
}
import {Controller, useForm} from "react-hook-form";
import {Box, Button, Stack, Typography} from "@mui/material";
import {zodResolver} from "@hookform/resolvers/zod";
import * as z from 'zod';
import {hasOptions, TYPE_OPTIONS} from "./type";
import AttributeOptionsEditor from "./AttributeOptionsEditor";
import ControlledTextField from "../mui/ControlledTextField";
import ControlledSwitch from "../mui/ControlledSwitch";
import AttributeTypeSelect from "./AttributeTypeSelect";

const schema = z.object({
    name: z.string().min(1).default(''),
    prefix: z.string().default(''),
    postfix: z.string().default(''),
    siNotation: z.boolean().default(false),
    type: z.enum(TYPE_OPTIONS.map(x => x.key)).default('TEXT'),
    options: z.array(z.object({id: z.string().optional(), label: z.string().min(1)}))
});

const defaultAttribute = {
    name: '',
    prefix: '',
    postfix: '',
    siNotation: false,
    type: 'TEXT',
    options: []
};

export default function AttributeForm({attribute, onSave, disabled = false}) {
    const {watch, control, handleSubmit} = useForm({
        defaultValues: Object.assign({}, attribute || defaultAttribute),
        resolver: zodResolver(schema)
    });

    const attributeType = watch('type');

    return (
        <Box component="form" onSubmit={handleSubmit(onSave)}>
            <Stack direction="column" alignItems="stretch" justifyContent="start" spacing={2}>
                <ControlledTextField name="name" label="Name" control={control} disabled={disabled}/>
                <ControlledTextField name="prefix" label="Prefix" control={control} disabled={disabled}/>
                <ControlledTextField name="postfix" label="Postfix" control={control} disabled={disabled}/>
                <ControlledSwitch name="siNotation" label="SI Notation" control={control} disabled={disabled}/>
                <Controller
                    name="type"
                    control={control}
                    render={({field}) => (
                        <AttributeTypeSelect value={field.value}
                                             ref={field.ref}
                                             onChange={field.onChange}
                                             onBlur={field.onBlur}
                                             disabled={disabled}
                        />
                    )}
                />
                {hasOptions(attributeType) && (
                    <>
                        <Typography variant="h4">Options</Typography>
                        <AttributeOptionsEditor name="options" control={control} disabled={disabled}/>
                    </>
                )}
            </Stack>
            <Stack direction="row" alignItems="start" justifyContent="end">
                <Button variant="contained" type="submit" sx={{mt: 2}} disabled={disabled}>Save</Button>
            </Stack>
        </Box>
    )
}
import {Container} from "@mui/material";
import LoginForm from "../components/LoginForm";
import {Route, Switch} from "wouter";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import AttributeList from "./pages/attributes/AttributeList";
import AttributeView from "./pages/attributes/AttributeView";
import AttributeEdit from "./pages/attributes/AttributeEdit";
import AttributeNew from "./pages/attributes/AttributeNew";
import ComponentTypeList from "./pages/componentTypes/ComponentTypeList";
import {useCurrentLogin} from "../utils/currentLogin";
import ComponentTypeView from "./pages/componentTypes/ComponentTypeView";

export default function MainContainer() {
    const currentLogin = useCurrentLogin();
    const isLoggedIn = currentLogin != null;

    return (
        <Container maxWidth="lg" sx={{paddingTop: 2}}>
            {!isLoggedIn && <LoginForm/>}
            {isLoggedIn && (
                <Switch>
                    <Route path="/" component={Home}/>

                    {/* Attributes */}
                    <Route path="/attributes" component={AttributeList}/>
                    <Route path="/attributes/new" component={AttributeNew}/>
                    <Route path="/attributes/:id" component={AttributeView}/>
                    <Route path="/attributes/:id/edit" component={AttributeEdit}/>

                    {/* Component Types */}
                    <Route path="/component-types" component={ComponentTypeList}/>
                    <Route path="/component-types/:id" component={ComponentTypeView}/>

                    <Route component={NotFound}/>
                </Switch>
            )}
        </Container>
    );
}
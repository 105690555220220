import {RecoilSync} from "recoil-sync";
import storage from './storage';

function onStorage(callback) {
    return /** @param {StorageEvent} ev */ ev => {
        callback(ev.key);
    }
}

export default function RecoilSyncStore({children, ...props}) {
    return (
        <RecoilSync
            storeKey="store.js"
            read={itemKey => storage.get(`recoil_${itemKey}`)}
            write={({diff}) => {
                for (const [key, value] of diff) {
                    storage.set(`recoil_${key}`, value);
                }
            }}
            listen={({updateItem}) => {
                let listener = onStorage(key => updateItem(key, storage.get(`recoil_${key}`)));
                window.addEventListener('storage', listener);
                return () => window.removeEventListener('storage', listener);
            }}
        >
            {children}
        </RecoilSync>
    );
}
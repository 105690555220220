import {Alert, Box, CircularProgress} from "@mui/material";

export default function LoadingBoundary({loading, error, children}) {
    if (error) {
        return (
            <Alert severity="error">{error.message ?? error}</Alert>
        );
    }
    if (loading) {
        return (
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <CircularProgress/>
            </Box>
        )
    }
    return typeof children === 'function' ? children() : children;
}
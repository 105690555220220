import {ApolloClient, HttpLink, ApolloLink, from} from "@apollo/client";
import {cache} from "./cache";
import {onError} from "@apollo/client/link/error";
import {getCurrentLogin, onCurrentLoginChange, setCurrentLogin} from "../utils/currentLogin";

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GQL_API });

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({headers = {}}) => {
        const login = getCurrentLogin();
        if (login) {
            headers.authorization = `Bearer ${login.token}`;
        }
        return {headers};
    });

    return forward(operation);
});

const errorLink = onError((error) => {
    if (error.graphQLErrors) {
        let invalidAuth = error.graphQLErrors.some(x =>  ['LOGIN_REQUIRED', 'UNAUTHORISED'].includes(x.extensions.code));
        if (invalidAuth) {
            setCurrentLogin(null);
        }
    }
});

const client = new ApolloClient({
    link: from([
        authMiddleware,
        errorLink,
        httpLink
    ]),
    cache,
});

onCurrentLoginChange(() => {
    client.resetStore()
        .catch(err => console.error(`Error resetting store: ${err.stack}`));
});

export default client;
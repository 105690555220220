import {useLocation} from "wouter";
import {useMutation} from "@apollo/client";
import ConfirmDelete from "./dialogs/ConfirmDelete";

export default function DeleteItemDialog({mutation, checkResult, afterUrl, thing, id, open, onClosed}) {
    const [, setLocation] = useLocation();
    const [deleteItem] = useMutation(mutation, { variables: {id} });

    const onDeleteClosed = confirm => {
        onClosed(confirm);
        if (confirm) {
            deleteItem()
                .then(({data}) => {
                    setLocation(afterUrl);
                    if (checkResult) {
                        const deleted = data[checkResult];
                        if (!deleted) {
                            console.warn('Attribute was not deleted!');
                        }
                    }
                })
                .catch(err => console.error(err));
        }
    };

    return <ConfirmDelete open={open} thing={thing} onClosed={onDeleteClosed}/>;
}
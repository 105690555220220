import {gql} from "@apollo/client";

const GET_ATTRIBUTE = gql`
    query GetAttribute($id: ID!) {
        attribute(id: $id) {
            id
            name
            type
            prefix
            postfix
            siNotation
            options {
                id
                label
            }
        }
    }
`;

export default GET_ATTRIBUTE;
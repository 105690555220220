import {Box, Stack, Typography} from "@mui/material";

export default function PageHeader({children, title}) {
    return (
        <Box mb={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Typography variant="h3" component="h2" mb={2}>{title}</Typography>
                {children}
            </Stack>
        </Box>
    )
}
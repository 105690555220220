/**
 * @type {{id: number, key: string, callback: function(key: string): void}[]}
 */
const currentObservers = [];
let nextId = 0;

window.addEventListener('storage', ev => {
    currentObservers.filter(x => x.key === ev.key).forEach(({callback}) => callback(ev.key, ev.newValue));
});

/**
 * @param {string} key
 * @param {function(key: string, newValue: string): void} callback
 * @return {(function(): void)} call to stop listening
 */
export default function onStorageChanged(key, callback) {
    const id = nextId++;
    currentObservers.push({id, key, callback});
    return () => {
        const index = currentObservers.findIndex(x => x.id === id);
        if (index !== -1) {
            currentObservers.splice(index, 1);
        }
    };
}
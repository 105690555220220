import PageTitle from "../../../components/PageTitle";
import PageHeader from "../PageHeader";
import GET_ATTRIBUTE from "../../../operations/queries/attribute";
import AppBreadcrumbs from "../../../components/AppBreadcrumbs";
import AttributeTypeDisplay from "../../../components/attributes/AttributeTypeDisplay";
import {CheckCircle} from "@mui/icons-material";
import {Button, ButtonGroup, Chip, Grid, Stack, Typography} from "@mui/material";
import {hasOptions} from "../../../components/attributes/type";
import {Link as LinkRouter} from "wouter";
import {useState} from "react";
import DELETE_ATTRIBUTE from "../../../operations/mutations/deleteAttribute";
import DeleteItemDialog from "../../../components/DeleteItemDialog";
import GridDataDisplay from "../../../components/GridDataDisplay";
import ItemQueryWrapper from "../../../components/ItemQueryWrapper";

const AttributeViewInner = ItemQueryWrapper(({id, attribute}) => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const onDeleteClosed = () => setDeleteDialogOpen(false);
    const onDeleteClicked = () => setDeleteDialogOpen(true);

    return (
        <>
            <PageTitle title={"Attribute"}/>
            <AppBreadcrumbs attributes title={attribute?.name}/>
            <PageHeader title={"Attribute"}>
                <ButtonGroup variant="contained">
                    <Button href={`/attributes/${id}/edit`} component={LinkRouter}>Edit</Button>
                    <Button color="error" onClick={onDeleteClicked}>Delete</Button>
                </ButtonGroup>
            </PageHeader>
            <DeleteItemDialog id={id}
                              thing="attribute"
                              afterUrl="/attributes"
                              mutation={DELETE_ATTRIBUTE}
                              checkResult="deleteAttribute"
                              open={deleteDialogOpen}
                              onClosed={onDeleteClosed}
            />
            <Grid container spacing={2}>
                <GridDataDisplay title="Name">{attribute.name}</GridDataDisplay>
                <GridDataDisplay title="Type"><AttributeTypeDisplay type={attribute.type}/></GridDataDisplay>
                {attribute.prefix && <GridDataDisplay title="Prefix">{attribute.prefix}</GridDataDisplay>}
                {attribute.postfix && <GridDataDisplay title="Postfix">{attribute.postfix}</GridDataDisplay>}
                {attribute.siNotation && <GridDataDisplay title="SI Notation"><CheckCircle color="success"/></GridDataDisplay>}
                {hasOptions(attribute.type) && (
                    <>
                        <Grid item xs={12} md={3} xl={2}>
                            <Typography variant="h6" component="h3">Options</Typography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={10}>
                            <Stack direction="row" spacing={2} justifyContent="start">
                                {attribute.options.map(x => <Chip key={x.id} label={x.label}/>)}
                            </Stack>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
});

export default function AttributeView({params: {id}}) {
    return <AttributeViewInner id={id} query={GET_ATTRIBUTE} dataKey="attribute"/>
};
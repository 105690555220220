import {AppBar, Box, Button, Container, Link, Stack, Toolbar, Typography} from "@mui/material";
import {useState} from "react";
import {Link as RouterLink, useLocation} from 'wouter';
import ConfirmLogoutDialog from "./dialogs/ConfirmLogoutDialog";
import {pathToRegexp} from "path-to-regexp";
import {setCurrentLogin, useCurrentLogin} from "../utils/currentLogin";

export default function Header() {
    const currentLogin = useCurrentLogin();
    const isLoggedIn = currentLogin != null;

    const [confirmingLogout, setConfirmingLogout] = useState(false);

    const [location] = useLocation();
    const attributesActive = pathToRegexp('/attributes', null, {end: false}).test(location);
    const componentTypesActive = pathToRegexp('/component-types', null, {end: false}).test(location);

    const onLogoutClick = () => {
        setConfirmingLogout(true);
    };

    const onLogoutDialogClosed = logout => {
        if (logout) {
            setCurrentLogin(null);
        }
        setConfirmingLogout(false);
    };

    return (
        <Box flexGrow="1">
            <AppBar position="static" >
                <Container maxWidth="lg">
                    <Toolbar disableGutters>
                        <Typography variant="h6" component="h1" mr={3}>
                            <Link color="inherit" underline="none" href="/" component={RouterLink}>
                                Parts of the Process
                            </Link>
                        </Typography>
                        {isLoggedIn && (
                            <>
                                <Stack direction="row" justifyItems="start" alignItems="center" spacing={2}>
                                    <Link color="inherit" underline={attributesActive ? 'always' : 'hover'}
                                          href="/attributes" component={RouterLink}>
                                        Attributes
                                    </Link>
                                    <Link color="inherit" underline={componentTypesActive ? 'always' : 'hover'}
                                          href="/component-types" component={RouterLink}>
                                        Types
                                    </Link>
                                </Stack>
                                <Box flexGrow={1}></Box>
                                <Button variant="text" color="inherit" onClick={onLogoutClick}>Logout</Button>
                            </>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
            <ConfirmLogoutDialog open={confirmingLogout} onClosed={onLogoutDialogClosed}/>
        </Box>
    );
}